<template>
    <div class="admin-container" v-if="users" >
        <h1 >Gestion des demandes</h1>
        <h2>{{ users[0].number }} {{users[0].name }}</h2>
        <div class="back-bar">
                    <router-link to="/admin/webinar-list" class="back-btn"></router-link>
        </div>
        <div class="table-container" >
            <table>
                <thead>
                    <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Ville</th>
                        <th>Pays</th>
                        <th>Fonction</th>
                        <th>Hôpital</th>
                        <th>Contact BACKBONE</th>
                        <th>Date demande</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody v-if="users">   
                    <tr v-for="(item, idx) in users" :key="idx">
                        <td>{{ item.firstname }}</td>
                        <td>{{ item.lastname }}</td>
                        <td>{{ item.email}}</td>
                        <td>{{ item.city }}</td>
                        <td>{{ item.country }}</td>
                        <td>{{ item.user_function_name }}</td>
                        <td>{{ item.hospital }}</td>
                        <td>{{ item.backbone_contact }}</td>
                        <td class="date-display">{{displayDate(item.webinar_user_date)}}</td>
                        <td>
                            <label class="switch">
                                <input type="checkbox" @click="changeState(item.webinar_user_state, item.user_id, $event)" :class="{ active : item.webinar_user_state == 2 }">
                                <span class="slider round"></span>
                            </label>
                            <div class="loader">
                                <Loader></Loader>
                            </div>
                        </td>
                    </tr>                                   
                </tbody>
            </table>
        </div>
        <NotifError :errorTxt="errorTxt" v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'
import Loader from './../../components/Loader'

export default {
    name : 'AdminUsersList',
    data() {
        return {
            users : null,
            errorTxt : null,
        }
    },
    components : {
        NotifError, Loader
    },
    mounted() {

        let vm = this
        this.errorTxt = null

        axios
            .get(store.getters.getURL + "webinars/getUsersWebinar.php?id=" + this.$route.params.id+ "&token=" + store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.users= response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err
            });
    },
    methods : {
        displayDate(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        },
        changeState(state, user, event) {

            event.target.parentNode.classList.add('inactive')
            event.target.parentNode.nextSibling.classList.add('active')
            
            let newState = 1;
            if(state == 1) {
                newState = 2;
            }

            let params = {
                user : user,
                state : newState,
                webinar : this.$route.params.id,
                token : store.state.JWT_TOKEN
            }

            let vm = this

             this.errorTxt = null

            axios
                .post(store.getters.getURL + "webinars/changeState.php", params )
                .then(function (response) {                 
                    if(response.data.state == 'success') {
                        event.target.parentNode.classList.remove('inactive')
                        event.target.parentNode.nextSibling.classList.remove('active')
                        vm.users.find(e => e.user_id === user ).webinar_user_state = newState
                    }else {
                        if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                            event.target.parentNode.classList.remove('inactive')
                            event.target.parentNode.nextSibling.classList.remove('active')
                            vm.errorTxt = 'An error has occured: ' + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    event.target.parentNode.classList.remove('inactive')
                    event.target.parentNode.nextSibling.classList.remove('active')
                    this.errorTxt = 'An error has occured: ' +  err
                });

        }
    }, 
}
</script>

<style lang="scss" scoped>

.switch.inactive {
    display: none;
}

.loader.active {
    display:block;
}

.loader {
    display: none;
}

</style>